<template>
  <div
      class="mapSHPFields p-4"
      style="min-width: 800px"
  >
    <p
        v-if="unassignedNames.length || unassociatedNames.length"
        class="p-4 bg-yellow-100"
    >
      You uploaded a file that uses different field names in the GeoJSON properties. Please select which property name
      these fields should be written into. Unassigned names: {{ unassignedNames }}
    </p>
    <div class="flex justify-center">
      <button
          v-if="unassignedNames.length === 0 || unassociatedNames.length === 0"
          class="rounded w-56 mb-8 h-10 bg-green-800 text-white"
          @click="onMapped"
      >CONFIRM FIELD MAPPING
      </button>
    </div>
    <div class="map flex flex-wrap">
      <div
          class="field w-1/2"
          v-for="name in fieldNames"
          :key="name"
      >
        <div class="w-80 flex flex-row items-center justify-between gap-4">
          <span class="flex items-center">{{ name }}</span>
          <div class="flex items-center bg-slate-300">
            <vSelect
                class="w-48 h-10"
                :options="unassignedNames"
                v-model="sortedNames[name]"
                :value="sortedNames[name]"
            ></vSelect>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {getMutableFieldNames} from "@/lib/fieldNames";
import {isArray, isString} from "@/lib/getVariableType";
import {computed, ref} from "vue";
import vSelect from "vue-select";

export default {
  name: "MapSHPFields",
  emits: ['mapped'],
  components: {
    vSelect,
  },
  props: {
    uploadedFieldNames: {
      type: Array,
      default: () => [],
      validator: value =>
          isArray(value) &&
          value.forEach(e =>
              isString(e) &&
              value.indexOf(e) === value.lastIndexOf(e) // unique
          )
    }
  },
  setup(props, {emit}) {
    const fieldNames = getMutableFieldNames()
    const sortedNames = ref(Object.fromEntries(
        fieldNames.reduce((names, name) => {
          const match = props.uploadedFieldNames.find(n => n.toLowerCase() === name.toLowerCase())
          names.push([name, (match ?? '')])
          return names
        }, [])
    ))

    const unassociatedNames = computed(() => props.uploadedFieldNames
        .filter(name => name.toLowerCase() !== 'geojson')
        .filter(name => !Object.values(sortedNames.value).includes(name))
    )
    const unassignedNames = computed(() => fieldNames
        .filter(name => name.toLowerCase() !== 'geojson')
        .filter(name => !Object.values(sortedNames.value).includes(name))
    )

    const onMapped = () => emit('mapped', sortedNames.value)

    return {
      fieldNames,
      sortedNames,
      unassignedNames,
      unassociatedNames,
      onMapped,
    }
  }
}
</script>

<style scoped>

</style>
