<template>
  <div class="js-upload mt-8 m-3 bg-blue-50 text-center rounded-3xl border-8 border-dashed border-blue-400">
    <h1 class="py-6 text-2xl text-center"> Upload a .zip file containing .SHP files by dropping it here</h1>
    <div uk-form-custom>
      <input
          type="file"
          multiple
      >
      <a>or by selecting it in your filesystem</a>
    </div>
  </div>
  <p class="p-4 w-full message">
    {{ message }}
  </p>
  <progress
      v-if="showProgress"
      id="js-progressbar"
      ref="progressBar"
      class="uk-progress"
      value="0"
      max="100"
  />
  <MapSHPFields
      class="mb-10"
      v-if="uploadedFieldNames.length"
      :uploadedFieldNames="uploadedFieldNames"
      @mapped="onSHPFieldsMapped"
  ></MapSHPFields>
</template>

<script>
import UIkit from 'uikit'
import {getAPIUrl} from "@/store/requests/lib/requestTypes";
import {state} from "@/store";
import axios from "axios";
import MapSHPFields from "@/components/MapSHPFields";
import router from "@/router";

export default {
  name: "Upload",
  components: {
    MapSHPFields,
  },
  data() {
    return {
      uploadUrl: getAPIUrl() + '/upload/' + state.value.user.email,
      showProgress: true,
      uploadedFieldNames: [],
      message: '',
      uploaded: 0,
      fileName: '',
    }
  },
  mounted() {
    const bar = this.$refs.progressBar
    const setMessage = this.setMessage
    const setUploadedFields = this.setUploadedFields
    const getErrorMessageFromResponseBody = body => {
      body = body.replaceAll('&quot;', '"')
          .replaceAll('&lt;', '<')
          .replaceAll('&gt;', '>')
          .replaceAll('&amp;', '&')
      const start = body.indexOf('<pre>') + 5
      const end = body.indexOf('<br> &nbsp;')
      return body.substring(start, end)
    }
    UIkit.upload('.js-upload', {

      url: this.uploadUrl,
      multiple: true,

      beforeSend: function () {
        this.uploadedFieldNames = []
      },
      error: function (err) {
        const {xhr} = err
        if (xhr) {
          console.log('error', getErrorMessageFromResponseBody(xhr?.response));
          setMessage('An error occurred. Please be aware that zip-files can only include files no folders.')
        } else {
          throw err
        }
      },
      loadStart: function (e) {
        // console.log('loadStart', arguments);

        this.showProgress = true

        bar.max = e.total;
        bar.value = e.loaded;
      },
      progress: function (e) {
        // console.log('progress', arguments);

        bar.max = e.total;
        bar.value = e.loaded;
      },
      loadEnd: function (e) {
        // console.log('loadEnd', arguments);

        bar.max = e.total;
        bar.value = e.loaded;
      },
      completeAll: function () {
        // console.log('completeAll', arguments);
        const {response} = arguments[0]
        const {message, insertedData} = JSON.parse(response)
        if (insertedData.length) {
          const {properties} = insertedData?.[0]
          const {fileName, uploaded} = properties

          const fieldNames = insertedData.reduce((names, entry) =>
                  Object.keys(entry.properties)
                      .reduce((uniqueNames, name) => {
                            if (!uniqueNames.includes(name)) uniqueNames.push(name)
                            return uniqueNames
                          },
                          []),
              [])

          const acceptedSectionsCount = message.match(/\d+/)?.[0] ?? 0
          const acceptedMessage = `Uploaded ${acceptedSectionsCount} new entries.`

          setMessage(acceptedMessage)
          setUploadedFields(fileName, uploaded, fieldNames)

        } else {
          setMessage('The uploaded file does not contain any geometrically unique entries.')
          setUploadedFields('', 0, [])
        }
      }
    })
  },
  methods: {
    setUploadedFields(fileName, uploaded, fieldNames) {
      const generatedFields = ['uri', 'uploaded', 'uploader', 'fileName']
      this.uploadedFieldNames = fieldNames.filter(f => !generatedFields.includes(f))
      this.fileName = fileName
      this.uploaded = uploaded
      this.showProgress = false
    },
    setMessage(msg) {
      this.message = msg
    },
    onSHPFieldsMapped(map) {
      axios({
        method: 'POST',
        url: getAPIUrl() + '/upload/' + state.value.user.email + '/' + this.uploaded,
        data: {
          fieldMap: {...map},
          fileName: this.fileName
        }
      })
          .then(() => {
            router.push({name: 'Map'})
          })
    }
  }
}
</script>

<style scoped>
.js-upload {
  height: 40vh;
}

</style>
