const fieldNames = [
  '_id',
  '__v',
  'uploadTime',
  'type',
  'properties',
  'properties.OBJECTID',
  'properties.Id',
  //
  'properties.Name',
  'properties.Route_Type',
  'properties.Type',
  'properties.Lower_Date',
  'properties.Low_Date_E',
  'properties.Upper_Date',
  'properties.Up_Date_E',
  'properties.Cons_per_e',
  'properties.Itinerary',
  'properties.Segment_s',
  // 'GeoJson',
  'properties.Descriptio',
  'properties.Citation',
  'properties.Bibliograp',
  'properties.Authors',
  'properties.uri',
  'properties.uploaded',
  'properties.uploader',
  'properties.fileName',
  'properties.Shape_Leng',
]
const hiddenFieldNames = [ // fields you would not search
  '_id',
  '__v',
  'uploadTime',
  'type',
  'properties',
  'properties.Id',
  'properties.OBJECTID',
  'properties.Shape_Leng',
]
const generatedFieldNames = [
  'properties.uri',
  'properties.uploaded',
  'properties.uploader',
  'properties.fileName',
]
export const getMutableFieldNames = () => fieldNames
    .filter(n => n.startsWith('properties.'))
    .filter(n => !generatedFieldNames.includes(n))
    .map(n => n.substring('properties.'.length))

export const getPropertyFieldNames = () => fieldNames
    .filter(n => n.startsWith('properties.'))
    .map(n => n.substring('properties.'.length))

export const getVisibleFieldNames = hiddenNames => {
  if (!hiddenNames) hiddenNames = hiddenFieldNames
  return fieldNames.filter(n => !hiddenNames.includes(n))
}
